import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import { ThemeProvider } from 'emotion-theming';

import { withTheme } from 'styled-components';

const theme = {
  breakpoints: ['40em', '52em', '64em', '90em', '120em'],
};
// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <ThemeProvider theme={{ theme }}>{element}</ThemeProvider>
    </AppProvider>
  );
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;
